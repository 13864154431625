import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  estacao: 0,
  selected: 2057,
  foto: 1
};
const actions = {
  setSelected({ commit }, selected) {
    commit("setSelected", selected);
  },
  setFoto({ commit }, foto) {
    commit("setFoto", foto);
  },
  incrementEstacao({ commit, state }) {
    let estacao = state.estacao + 1;
    if (estacao > 3) estacao = 0;
    commit("setEstacao", estacao);
  },
  decrementEstacao({ commit, state }) {
    let estacao = state.estacao - 1;
    if (estacao < 0) estacao = 3;
    commit("setEstacao", estacao);
  }
};
const mutations = {
  setEstacao(state, estacao) {
    state.estacao = estacao;
  },
  setSelected(state, selected) {
    state.selected = selected;
  },
  setFoto(state, foto) {
    state.foto = foto;
  }
};

const store = new Vuex.Store({ state, actions, mutations });
export default store;
