<template>
  <div id="theCards">
    <card-mobile
      v-for="estampa in linha.estampas"
      :estampa="estampa"
      :selected="selected"
      @atualiza="atualiza(estampa.id)"
      :key="estampa.id"
    ></card-mobile>
  </div>
</template>

<script>
import CardMobile from "@/components/CardMobile";
export default {
  name: "CardsMobile",
  components: { CardMobile },
  props: ["linha"],
  data() {
    return {};
  },
  computed: {
    selected() {
      return this.$store.state.selected;
    }
  }
};
</script>

<style scoped>
#theCards {
  padding: 15px 0 0 0;
  display: flex;
  /* flex-wrap: wrap; */
}
</style>