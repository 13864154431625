import Vue from "vue";
import VueAnalytics from "vue-analytics";
import App from "./App.vue";
import store from "./store";
import "core-js";
import "core-js/shim";
import "@babel/polyfill";

console.log(
  "\x1b[35m%s",
  `
Desenvolvido por: 

::::    :::: :::       :::        :::    ::::::::: :::::::::  
+:+:+: :+:+:+:+:       :+:      :+: :+:  :+:    :+::+:    :+: 
+:+ +:+:+ +:++:+       +:+     +:+   +:+ +:+    +:++:+    +:+ 
+#+  +:+  +#++#+  +:+  +#+    +#++:++#++:+#++:++#+ +#++:++#+  
+#+       +#++#+ +#+#+ +#+    +#+     +#++#+       +#+        
#+#       #+# #+#+# #+#+#     #+#     #+##+#       #+#        
###       ###  ###   ###      ###     ######       ### 

© 2019 Mauro Widman
`
);

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: "UA-145733776-1"
});

new Vue({
  store,
  render: h => h(App)
}).$mount("#app");
