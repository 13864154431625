<template>
  <div id="app">
    <div v-if="!isMobile()">
      <desktop :linhas="linhas"></desktop>
    </div>
    <div v-else>
      <mobile :linhas="linhas"></mobile>
    </div>
  </div>
</template>

<script>
import Desktop from "@/Desktop";
import Mobile from "@/Mobile";

export default {
  name: "app",
  components: { Desktop, Mobile },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      linhas: [
        {
          titulo: "Primavera",
          subTitulo: "Espírito colorido de primavera",
          texto:
            "Flores e folhas revelam a alegria da estação, confira as 10 estampas dessa estação.",
          estampas: [
            {
              id: 1898,
              nome: "Savanne"
            },
            {
              id: 1733,
              nome: "Chia"
            },
            { 
              id: 1718,
              nome: "Barley"
            },
            {
              id: 1830,
              nome: "Spaghetti"
            },
            {
              id: 1742,
              nome: "Horta Licht Crijs"
            },
            {
              id: 1721,
              nome: "Blue Leaves"
            },
            {
              id: 2123,
              nome: "Oaken"
            },
            {
              id: 1997,
              nome: "Silene"
            },
            {
              id: 2177,
              nome: "Fontainebleau"
            },
            {
              id: 1829,
              nome: "Palm"
            },
            {
              id: 1893,
              nome: "Focus"
            }
          ]
        },
        {
          titulo: "Verão",
          subTitulo: "Doces lembranças no verão",
          texto:
            "Tons doces e calorosos para esquentar o clima, confira as 14 estampas dessa estação.",
          estampas: [
            {
              id: 2062,
              nome: "Bangor"
            },
            {
              id: 1905,
              nome: "Fade"
            },
            {
              id: 1914,
              nome: "Nomade"
            },
            {
              id: 2194,
              nome: "Linen"
            },
            {
              id: 1908,
              nome: "Jewel"
            },
            {
              id: 2012,
              nome: "Bali"
            },
            {
              id: 1916,
              nome: "Royal Ladderband Eruit"
            },
            {
              id: 2006,
              nome: "Morag"
            },
            {
              id: 1888,
              nome: "Canelle"
            },
            {
              id: 2060,
              nome: "Carcassone"
            },
            {
              id: 2067,
              nome: "Chesterfield"
            },
            {
              id: 1754,
              nome: "Magic Balls"
            },
            {
              id: 1904,
              nome: "Middle"
            },
            {
              id: 1915,
              nome: "Marine"
            }
          ]
        },
        {
          titulo: "Outono",
          subTitulo: "Consciência criativa de outono",
          texto:
            "Mais clássico, reproduz o ar intimista dessa época, confira as 13 estampas dessa estação.",
          estampas: [
            {
              id: 1725,
              nome: "Pencil"
            },
            {
              id: 1726,
              nome: "Bay"
            },
            {
              id: 1832,
              nome: "Folly"
            },
            {
              id: 1835,
              nome: "Transistor"
            },
            {
              id: 1921,
              nome: "Mirtillo"
            },
            {
              id: 1922,
              nome: "Caviar"
            },
            {
              id: 1998,
              nome: "Milton"
            },
            {
              id: 1909,
              nome: "Indes"
            },
            {
              id: 1999,
              nome: "Artemis"
            },
            {
              id: 2184,
              nome: "Moreno"
            },
            {
              id: 2189,
              nome: "Bias"
            },
            {
              id: 2190,
              nome: "Coil"
            }
          ]
        },
        {
          titulo: "Inverno",
          subTitulo: "Força silenciosa no inverno",
          texto:
            "Com desenhos claros e sutis, chega minimalista, confira as 13 estampas dessa estação.",
          estampas: [
            {
              id: 1711,
              nome: "Brad"
            },
            {
              id: 1728,
              nome: "Marble"
            },
            {
              id: 1730,
              nome: "Dimension"
            },
            {
              id: 1736,
              nome: "Trialine"
            },
            {
              id: 1762,
              nome: "Magic Balls Grey"
            },
            {
              id: 1763,
              nome: "Herringbone"
            },
            {
              id: 1833,
              nome: "Double"
            },
            {
              id: 1837,
              nome: "Ink"
            },
            {
              id: 1918,
              nome: "More"
            },
            {
              id: 1920,
              nome: "Roux"
            },
            {
              id: 2004,
              nome: "Massimo"
            },
            {
              id: 2057,
              nome: "Whiteheaven"
            },
            {
              id: 2185,
              nome: "Asbury Park"
            }
          ]
        }
      ]
    };
  }
};
</script>

<style>
#app {
  font-family: "Open Sans", sans-serif;
}
</style>
