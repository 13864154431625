<template>
  <div id="header">
    <div id="orange">
      <div id="etherea">
        <a
          href="https://www.hunterdouglas.com.br/cortinas/produtos/cortina-rolo-etherea"
          target="_blank"
        >
          <h1>ETHEREA</h1>
        </a>
        <p>Movimentos da natureza</p>
      </div>
      <div id="logo">
        <a href="https://www.hunterdouglas.com.br" target="_blank">
          <img src="@/assets/hunter_douglas.svg" alt />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MwHeader"
};
</script>

<style scoped>
#orange {
  background-color: #e7792b;
  color: #fff;
  height: 6vw;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
#etherea {
  float: left;
  margin-top: 5px;
  margin-left: 30px;
}
#etherea a {
  color: #fff;
  text-decoration: none;
}
#etherea h1 {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-weight: 200;
  font-size: 4.5vw;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  margin-top: 5px;
  padding-top: 0px;
  margin-block-end: -3px;
}
#etherea p {
  margin: -10px 0 0 0;
  padding: 0;
  font-style: italic;
  font-size: 1vw;
}
#logo {
  float: right;
  max-height: 27px;
  margin-top: 4vh;
  margin-right: 2vw;
  vertical-align: middle;
}
#logo img {
  height: 3.5vh;
}
</style>