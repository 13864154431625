<template>
  <div>
    <div style="display: flex; ">
      <div id="preload" style="display:none;">
        <img
          v-for="estampa in linhas[estacao].estampas"
          :src="require('./assets/foto1_'+estampa.id+'.jpg')"
          :key="estampa.id"
        />
      </div>
      <div id="imagem">
        <imagem></imagem>
      </div>
      <div id="conteudo">
        <div class="alturafixa">
          <mw-header></mw-header>
          <mw-sub-header :linha="linhas[estacao]"></mw-sub-header>
        </div>
        <div id="cards">
          <cards :linha="linhas[estacao]"></cards>
        </div>
      </div>
    </div>
    <div style="display: flex; padding-top: 10px; padding-bottom: 10px; flex-direction: row; justify-content: center;background:#d0d0d0;">
      <!-- <img src="@/assets/logoHD2.png" alt="" width=220> -->
      <p style="padding: 15px;">Siga-nos nas redes sociais</p>
      <ul class="social" v-for="social in sociais" :key="social.link">
        <li style="padding: 5px;">
            <a :href="social.link">
              <img :src="require('./assets/'+social.image+'.png')" alt="" width="40px">
            </a>
          </li>
      </ul> 
      <p style="font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: normal; padding: 5px 20px;">As estampas deste site são apenas referências, podendo existir variação entre<br> as cores apresentadas na tela e as cores reais dos produtos comercializados</p>
    </div>
  </div>
</template>

<script>
import Imagem from "@/components/Imagem";
import MwHeader from "@/components/MwHeader";
import MwSubHeader from "@/components/MwSubHeader";
import Cards from "@/components/Cards";
export default {
  name: "Desktop",
  props: ["linhas"],
  components: { MwHeader, MwSubHeader, Cards, Imagem },
  data() {
    return {
      hoover: true,
      sociais: [
        {nome: 'Facebook',
        link: 'https://www.facebook.com/hunterdouglas.br',
        image: 'facebook1',
        hoover: 'facebook2'},
        {nome: 'Instagram',
        link: 'https://www.instagram.com/hunterdouglasbrasil/',
        image: 'Instagram-social-circle_grey',
        hoover: 'Instagram-social-circle_red'},
        {nome: 'Pinterest',
        link: 'https://br.pinterest.com/hunterdouglasbr/',
        image: 'pinterest1',
        hoover: 'pinterest2'},
        {nome: 'YouTube',
        link: 'https://www.youtube.com/user/HunterDouglasBrasil',
        image: 'YouTube-social-circle_grey',
        hoover: 'YouTube-social-circle_red'},
        {nome: 'LinkedIn',
        link: 'https://www.linkedin.com/company/hunterdouglasdobrasil/',
        image: 'LinkedIn_grey',
        hoover: 'LinkedIn_grey'},
      ]
    }
  },
  computed: {
    estacao() {
      return this.$store.state.estacao;
    }
  }
};
</script>

<style scoped>
#imagem {
  max-width: 35vw;
  height: 100vh;
  flex: 0 0 auto;
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
}
#conteudo {
  flex: 1 1 auto;
  /* margin-left: 800px; */
}
.alturafixa {
  height: 50vh;
}
#cards {
  overflow-y: scroll;
  height: 45vh;
}
</style>