<template>
  <div id="theCards">
    <card
      v-for="estampa in linha.estampas"
      :estampa="estampa"
      :selected="selected"
      @atualiza="atualiza(estampa.id)"
      :key="estampa.id"
    ></card>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  name: "Cards",
  components: { Card },
  props: ["linha"],
  data() {
    return {};
  },
  computed: {
    selected() {
      return this.$store.state.selected;
    }
  }
};
</script>

<style scoped>
#rodape {
  width: 100%;
  height: 25px;
}
#rodape p {
  margin-top: -18px;
  font-size: 10px;
  text-align: center;
  color: gray;
}
#theCards {
  display: flex;
  flex-wrap: wrap;
}
</style>