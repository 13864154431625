<template>
  <div>
    <div id="texto">
      <p>
        Aproveite o 
        <span>Simulador de ambientes Etherea</span> para visualizar de maneira ágil e intuitiva o amplo catálogo de estampas da 
        <span>Coleção Etherea</span>.
      </p>
      <p>São 50 estampas diferentes, separadas por estações, que evocam múltiplas sensações, levando beleza e bem-estar ao ambiente através de uma <span>Cortina Rolô</span>. Aproveite a experiência no 
        <span>Simulador de ambientes Etherea</span> e veja suas estampas preferidas aplicadas aos ambientes.
      </p>
    </div>
    <div class="container">
      <div>
        <img src="@/assets/chevron-left-solid.svg" width="25px" alt @click="decrementEstacao" />
      </div>
      <div id="estacao">
        <h2>{{linha.titulo}}</h2>
      </div>
      <div>
        <img src="@/assets/chevron-right-solid.svg" width="25px" alt @click="incrementEstacao" />
      </div>
    </div>
    <div>
      <p class="texto">{{linha.texto}}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MwSubHeaderMobile",
  props: ["linha"],
  methods: {
    ...mapActions(["incrementEstacao", "decrementEstacao"]),
    alerta() {
      alert();
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  color: black;
  margin: 0 50px;
}
.setas {
  width: 50px;
}

#texto {
  padding: 10px 10px 5px 10px;
}
#texto p {
  font-size: 3vw;
  color: black;
  line-height: 125%;
  padding-bottom: 1vh;
}
#texto p span {
  color: black;
  font-weight: bold;
  line-height: 125%;
}
#estacao {
  margin: auto;
  width: 225px;
  text-align: center;
}
#estacao h2 {
  padding-top: 5px;
  font-family: "Yanone Kaffeesatz", sans-serif;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 5vh;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#estacao p {
  margin-top: 5px;
  font-style: italic;
  font-size: 1.6vh;
}

.texto {
  margin: 10px 0;
  font-style: normal;
  font-size: 2.5vw;
  text-align: center;
}
</style>