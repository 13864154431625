<template>
  <div
    id="imagem"
    :style="{ backgroundImage: 'url(' + require('../assets/foto'+$store.state.foto+'_'+$store.state.selected+'.jpg') + ')' }"
  ></div>
</template>

<script>
export default {
  name: "ImagemMobile",
  computed: {
    fotoUrl() {
      let other = this.$store.state.foto == 1 ? 2 : 1;
      return require("../assets/foto" +
        other +
        "_" +
        this.$store.state.selected +
        ".jpg");
    }
  }
};
</script>

<style scp[ed]>
#imagem {
  width: 100%;
  height: 500px;
  /* height: 100%; */
  background-size: cover;
  -webkit-transition: background 300ms ease-in 2s; /* property duration timing-function delay */
  -moz-transition: background 300ms ease-in 2s;
  -o-transition: background 300ms ease-in 2s;
  transition: background 300ms ease-in 2s;

  -webkit-transition-property: background;
  -webkit-transition-duration: 300ms;
  -webkit-transition-timing-function: ease-in;
  -webkit-transition-delay: 100ms;

  -moz-transition-property: background;
  -moz-transition-duration: 300ms;
  -moz-transition-timing-function: ease-in;
  -moz-transition-delay: 100ms;

  -o-transition-property: background;
  -o-transition-duration: 300ms;
  -o-transition-timing-function: ease-in;
  -o-transition-delay: 100ms;

  transition-property: background;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
  transition-delay: 100ms;
}
#imagem img {
  width: 100%;
  margin-top: 0px;
  /* margin-left: 2vw; */
  /* box-shadow: 0px 0px 10px #fff; */
}
</style>