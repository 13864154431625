<template>
  <div id="card" @click="clicou" v-bind:class="estampa.id == selected ? 'selected' : ''">
    <div
      id="textura"
      :style="{ backgroundImage: 'url(' + require('../assets/thumb_'+estampa.id+'.jpg') + ')' }"
    ></div>
    <div id="tag">
      <p v-bind:class="estampa.id == selected ? 'selected' : ''">{{estampa.nome}} {{estampa.id}}</p>
    </div>
  </div>
</template>

<script>
import { event } from "vue-analytics";
export default {
  name: "card",
  props: ["estampa"],
  methods: {
    clicou() {
      event({
        eventCategory: "etherea",
        eventAction: "clicou",
        eventLabel: "padrao",
        eventValue: this.estampa.id
      });
      this.$store.dispatch("setSelected", this.estampa.id);
    }
  },
  computed: {
    selected() {
      return this.$store.state.selected;
    }
  }
};
</script>

<style scoped>
#card {
  width: 12.5vw;
  height: 26vh;
  margin: 0 1.5vw 5vh 1.5vw;
  box-shadow: 0px 6px 6px #c0c0c0;
  text-align: center;
  font-weight: 200;
  -webkit-transition: all 50ms ease-in 50ms; /* property duration timing-function delay */
  -moz-transition: all 50ms ease-in 50ms;
  -o-transition: all 50ms ease-in 50ms;
  transition: all 50ms ease-in 50ms;
  transition-property: box-shadow, height, width, margin;

  -webkit-transition-property: box-shadow, height, width, margin;
  -webkit-transition-duration: 101ms;
  -webkit-transition-timing-function: ease-in;
  -webkit-transition-delay: 100ms;

  -moz-transition-property: box-shadow, height, width, margin;
  -moz-transition-duration: 101ms;
  -moz-transition-timing-function: ease-in;
  -moz-transition-delay: 100ms;

  -o-transition-property: box-shadow, height, width, margin;
  -o-transition-duration: 101ms;
  -o-transition-timing-function: ease-in;
  -o-transition-delay: 100ms;

  transition-property: box-shadow, height, width, margin;
  transition-duration: 101ms;
  transition-timing-function: ease-in;
  transition-delay: 100ms;
}
#card:hover {
  cursor: pointer;
  width: 13.3vw;
  height: 25.5vh;
  margin: -2vh 1.1vw 0 1.1vw;
  box-shadow: 0px 9px 9px #b0b0b0;
}

#textura {
  background-color: grey;
  min-height: 22vh;
}
#tag {
  line-height: 4vh;
  background-color: white;
}
.selected {
  background-color: #e7792b;
  color: white;
  font-weight: 400;
}
#tag p {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 2.2vh;
  font-style: normal;
  text-transform: uppercase;
}
</style>