<template>
  <div>
    <div id="preload" style="display:none;">
      <img
        v-for="estampa in linhas[estacao].estampas"
        :src="require('./assets/foto1_'+estampa.id+'.jpg')"
        :key="estampa.id"
      />
    </div>
    <div class="alturafixa">
      <mw-header-mobile></mw-header-mobile>
      <mw-sub-header-mobile :linha="linhas[estacao]"></mw-sub-header-mobile>
    </div>
    <div id="imagemMobile">
      <imagem-mobile></imagem-mobile>
    </div>

    <div id="conteudo">
      <div id="cards" :style="cardsWidth">
        <cards-mobile :linha="linhas[estacao]"></cards-mobile>
      </div>
    </div>
    <div style="margin-top: 10px;background:#d0d0d0;">
      <p style="text-align: center; font-size: 12px; padding-top: 15px;">Siga-nos nas redes sociais</p>
      <div style="display: flex; padding-top: 10px; padding-bottom: 5px; flex-direction: row; justify-content: center;">
      <ul class="social" v-for="social in sociais" :key="social.link">
        <li style="padding: 5px;">
            <a :href="social.link">
              <img :src="require('./assets/'+social.image+'.png')" alt="">
            </a>
          </li>
      </ul> </div>
      <p style="text-align: center; font-family: 'Open Sans', sans-serif; font-size: 12px; font-weight: normal; padding: 5px 20px 20px 20px;">As estampas deste site são apenas referências, podendo existir variação entre as cores apresentadas na tela e as cores reais dos produtos comercializados.</p>
    </div>
  </div>
</template>

<script>
import MwHeaderMobile from "@/components/MwHeaderMobile";
import MwSubHeaderMobile from "@/components/MwSubHeaderMobile";
import ImagemMobile from "@/components/ImagemMobile";
import CardsMobile from "@/components/CardsMobile";
export default {
  name: "Mobile",
  props: ["linhas"],
  components: { MwHeaderMobile, MwSubHeaderMobile, CardsMobile, ImagemMobile },
  data() {
    return {
      hoover: true,
      sociais: [
        {nome: 'Facebook',
        link: 'https://www.facebook.com/hunterdouglas.br',
        image: 'facebook1',
        hoover: 'facebook2'},
        {nome: 'Instagram',
        link: 'https://www.instagram.com/hunterdouglasbrasil/',
        image: 'Instagram-social-circle_grey',
        hoover: 'Instagram-social-circle_red'},
        {nome: 'Pinterest',
        link: 'https://br.pinterest.com/hunterdouglasbr/',
        image: 'pinterest1',
        hoover: 'pinterest2'},
        {nome: 'YouTube',
        link: 'https://www.youtube.com/user/HunterDouglasBrasil',
        image: 'YouTube-social-circle_grey',
        hoover: 'YouTube-social-circle_red'},
        {nome: 'LinkedIn',
        link: 'https://www.linkedin.com/company/hunterdouglasdobrasil/',
        image: 'LinkedIn_grey',
        hoover: 'LinkedIn_grey'},
      ]
    }
  },
  computed: {
    estacao() {
      return this.$store.state.estacao;
    },
    cardsWidth() {
      return {
        width:
          this.linhas[this.$store.state.estacao].estampas.length * 19 + "vh"
      };
    }
  }
};
</script>

<style scoped>
#imagemMobile p {
  color: grey;
  font-size: 10px;
  text-align: center;
}
#conteudo {
  overflow-y: scroll;
}
/* .alturafixa {
  height: 38vh;
} */
/* #cards {
  width: 190vh;
} */
#rodape p {
  font-size: 12px;
  text-align: center;
  color: gray;
}
</style>